import React, { Fragment } from 'react'
import { useEffect } from 'react'

const Scripter = ({ scriptText }) => {
  useEffect(() => {
    const script = document.createElement('script')
    script.type = 'application/ld+json'
    script.innerHTML = scriptText

    document.head.appendChild(script)
  }, [])

  return <Fragment />
}

export const Course = () => {
  return (
    <Scripter
      scriptText={`{
    "@context": "https://schema.org",
    "@type": "Course",
    "name": "Luminosity Masking Basics",
    "description": "Free introductory image post-processing course on use of luminosity masks in Adobe Photoshop",
    "provider": {
      "@type": "Organization",
      "name": "ARCPanel",
      "sameAs": "https://arcpanel.averin.photo"
    }
  }`}
    />
  )
}

export const FAQ = ({ faq }) => {
  const entries = faq.map(({ q, a }) => {
    return `{
"@type": "Question",
"name": "${q}",
"acceptedAnswer": {
  "@type": "Answer",
  "text": "${a}"
}}`
  })
  return (
    <Scripter
      scriptText={`{
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [ ${entries}]
  }`}
    />
  )
}
